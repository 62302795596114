var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"close-on-click-modal":false,"close-on-press-escape":false,"title":"上传RN应用包","visible":_vm.visible,"width":"40%"},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.closeDialog,"open":_vm.openDialog}},[_c('el-form',{ref:"ruleForm",staticClass:"firmware_form",attrs:{"model":_vm.form,"size":"small","rules":_vm.rules,"label-width":_vm.formLabelWidth}},[_c('el-form-item',{attrs:{"label":"版本号：","prop":"panelVersion"}},[_c('el-input',{attrs:{"placeholder":"格式仅支持字母及数字及小数点"},model:{value:(_vm.form.panelVersion),callback:function ($$v) {_vm.$set(_vm.form, "panelVersion", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.panelVersion"}})],1),_c('el-form-item',{attrs:{"label":"RN应用包：","required":"","error":_vm.err}},[_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"upload-title"},[_vm._v("Android包：")]),_c('br'),_c('el-upload',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.load.android),expression:"load.android"}],ref:"upload",staticClass:"upload-div",attrs:{"action":_vm.$message.uploadImgUrl,"show-file-list":false,"data":{
                           bucket: 'panel',
                           holdName: 'Y' // 保持上传文件名不变
                        },"headers":{
                            accessToken: _vm.$token.getToken().accessToken,
                            refreshToken: _vm.$token.getToken().refreshToken,
                            language: _vm.$message.language
                        },"on-preview":_vm.handlePreview,"on-remove":_vm.handleRemove,"on-success":($event) => _vm.handleSuccess($event, 'android'),"before-upload":($event) => _vm.beforeUpload($event, 'android'),"accept":".zip"}},[(_vm.file.android)?_c('img',{staticClass:"icon-file",attrs:{"src":require("../../../../../../assets/icon_file.png")}}):_c('i',{staticClass:"el-icon-plus uploader-icon"})])],1),_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"upload-title"},[_vm._v("IOS包：")]),_c('br'),_c('el-upload',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.load.ios),expression:"load.ios"}],ref:"upload",staticClass:"upload-div",attrs:{"action":_vm.$message.uploadImgUrl,"show-file-list":false,"data":{
                           bucket: 'panel',
                           holdName: 'Y' // 保持上传文件名不变
                        },"headers":{
                            accessToken: _vm.$token.getToken().accessToken,
                            refreshToken: _vm.$token.getToken().refreshToken,
                            language: _vm.$message.language
                        },"on-preview":_vm.handlePreview,"on-remove":_vm.handleRemove,"on-success":($event) => _vm.handleSuccess($event, 'ios'),"before-upload":($event) => _vm.beforeUpload($event, 'ios'),"accept":".zip"}},[(_vm.file.ios)?_c('img',{staticClass:"icon-file",attrs:{"src":require("../../../../../../assets/icon_file.png")}}):_c('i',{staticClass:"el-icon-plus uploader-icon"})])],1),_c('el-col',{attrs:{"span":8}},[_c('div',{staticClass:"upload-title"},[_vm._v("HD包：")]),_c('br'),_c('el-upload',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.load.hd),expression:"load.hd"}],ref:"upload",staticClass:"upload-div",attrs:{"action":_vm.$message.uploadImgUrl,"show-file-list":false,"data":{
                           bucket: 'panel',
                           holdName: 'Y' // 保持上传文件名不变
                        },"headers":{
                            accessToken: _vm.$token.getToken().accessToken,
                            refreshToken: _vm.$token.getToken().refreshToken,
                             language: _vm.$message.language
                        },"on-preview":_vm.handlePreview,"on-remove":_vm.handleRemove,"on-success":($event) => _vm.handleSuccess($event, 'hd'),"before-upload":($event) => _vm.beforeUpload($event, 'hd'),"accept":".zip"}},[(_vm.file.hd)?_c('img',{staticClass:"icon-file",attrs:{"src":require("../../../../../../assets/icon_file.png")}}):_c('i',{staticClass:"el-icon-plus uploader-icon"})])],1)],1)],1),_c('el-form-item',{attrs:{"label":"更新日志：","prop":"log"}},[_c('el-input',{attrs:{"type":"textarea","rows":4,"show-word-limit":"","maxlength":"300","placeholder":"请输入更新日志"},model:{value:(_vm.form.log),callback:function ($$v) {_vm.$set(_vm.form, "log", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.log"}})],1)],1),_c('div',{staticClass:"demo-drawer__footer",staticStyle:{"text-align":"right","margin-top":"50px"}},[_c('el-button',{on:{"click":_vm.closeDialog}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v("确 定")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }