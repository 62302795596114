var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body"},[_c('el-timeline',{staticStyle:{"margin-top":"20px"}},_vm._l((_vm.activities),function(item,index){return _c('el-timeline-item',{key:index,attrs:{"timestamp":item,"color":"#1890FF","size":"large","icon":'iconfont_al step_icon icon-' + (index+1),"placement":"top"}},[(index == 0)?_c('div',{staticClass:"item"},[_c('el-form',{ref:"ruleForm",refInFor:true,staticClass:"basic_form1",attrs:{"model":_vm.form1,"size":"small","rules":_vm.rules1,"label-width":_vm.formLabelWidth,"disabled":_vm.form1.state == 3}},[_c('el-form-item',{attrs:{"label":"产品名称：","prop":"productName"}},[_c('el-input',{attrs:{"placeholder":"请输入产品名称"},model:{value:(_vm.form1.productName),callback:function ($$v) {_vm.$set(_vm.form1, "productName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form1.productName"}})],1),_c('el-form-item',{attrs:{"label":"产品品类："}},[_vm._v(" "+_vm._s(_vm.form1.categoryName)+" ")]),_c('el-form-item',{attrs:{"label":"产品型号：","prop":"model"}},[_c('el-input',{attrs:{"placeholder":"请输入型号"},model:{value:(_vm.form1.model),callback:function ($$v) {_vm.$set(_vm.form1, "model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form1.model"}})],1),_c('el-form-item',{attrs:{"label":"产品拟物图：","required":"","error":_vm.err.fileErr}},[(_vm.form1.productImageUrl)?_c('div',{staticClass:"category_item"},[_c('div',{staticClass:"image"},[(_vm.form1.state != 3)?_c('el-button',{staticClass:"close_img",attrs:{"type":"text","icon":"el-icon-error","size":"medium"},on:{"click":function($event){_vm.form1.productImageUrl = ''}}}):_vm._e(),_c('img',{attrs:{"src":_vm.form1.productImageUrl,"width":"94","height":"94"}})],1)]):_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":_vm.$message.uploadImgUrl,"data":{
                                bucket: 'product'
                            },"headers":{
                                accessToken: _vm.$token.getToken().accessToken,
                                refreshToken: _vm.$token.getToken().refreshToken,
                            language: _vm.$message.language
                            },"show-file-list":false,"on-success":_vm.handleAvatarSuccess,"on-remove":_vm.onRemove,"accept":".png","on-error":_vm.onErr,"before-upload":_vm.beforeAvatarUpload}},[_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})])],1)],1)],1):_vm._e(),(index == 1)?_c('div',{staticClass:"item"},[_c('el-form',{ref:"ruleForm1",refInFor:true,staticClass:"basic_form2",attrs:{"model":_vm.form2,"rules":_vm.rules2,"label-width":'10px',"size":"small"}},[_c('el-form-item',{attrs:{"label":" ","prop":"firmwareId"}},[_c('el-select',{attrs:{"popper-append-to-body":false,"placeholder":"请选择固件","disabled":_vm.form1.state == 3},model:{value:(_vm.form2.firmwareId),callback:function ($$v) {_vm.$set(_vm.form2, "firmwareId", $$v)},expression:"form2.firmwareId"}},_vm._l((_vm.firmwareList),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.name,"value":item.code}})}),1)],1)],1)],1):_vm._e(),(index == 2)?_c('div',{staticClass:"item"},[_c('el-form',{ref:"ruleForm2",refInFor:true,staticClass:"basic_form3",attrs:{"model":_vm.form3,"rules":_vm.rules3,"label-width":'10px',"size":"small"}},[_c('el-form-item',{attrs:{"label":" ","prop":"panelId"}},[_c('el-select',{attrs:{"popper-append-to-body":false,"placeholder":"请选择面板","disabled":_vm.form1.state == 3},model:{value:(_vm.form3.panelId),callback:function ($$v) {_vm.$set(_vm.form3, "panelId", $$v)},expression:"form3.panelId"}},_vm._l((_vm.panelList),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.name,"value":item.code}})}),1)],1)],1)],1):_vm._e(),(index == 3)?_c('div',{staticClass:"item"},[_c('el-button',{attrs:{"loading":_vm.submitLoading,"size":"small","type":"primary","disabled":_vm.form1.state == 3},on:{"click":function($event){return _vm.submit()}}},[_vm._v("确认上线 ")])],1):_vm._e()])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }