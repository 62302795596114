var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"close-on-click-modal":false,"close-on-press-escape":false,"title":_vm.type === 1 ? '上传SDK固件包' : _vm.type === 2 ? '上传MCU固件' : '新增固件',"visible":_vm.visible,"width":"30%"},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.closeDialog,"open":_vm.openDialog}},[_c('el-form',{ref:"ruleForm",staticClass:"firmware_form",attrs:{"model":_vm.form,"size":"small","rules":_vm.rules,"label-width":_vm.formLabelWidth}},[_c('el-form-item',{attrs:{"label":"固件版本：","prop":"firmwareVersion"}},[_c('el-input',{attrs:{"placeholder":"格式仅支持字母及数字及小数点"},model:{value:(_vm.form.firmwareVersion),callback:function ($$v) {_vm.$set(_vm.form, "firmwareVersion", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.firmwareVersion"}})],1),_c('el-form-item',{attrs:{"label":"固件包：","required":"","error":_vm.err}},[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"action":_vm.$message.uploadImgUrl,"data":{
                    bucket: 'firmware'
                },"headers":{
                    accessToken: _vm.$token.getToken().accessToken,
                    refreshToken: _vm.$token.getToken().refreshToken,
                    language: _vm.$message.language
                },"on-preview":_vm.handlePreview,"on-remove":_vm.handleRemove,"before-remove":_vm.beforeRemove,"on-success":_vm.handleSuccess,"limit":1,"accept":".img,.bin,.zip,.hex,.apk","file-list":_vm.fileList,"on-exceed":_vm.handleExceed}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")])],1)],1),_c('el-form-item',{attrs:{"label":"更新日志：","prop":"log"}},[_c('el-input',{attrs:{"type":"textarea","rows":4,"show-word-limit":"","maxlength":"300","placeholder":"请输入更新日志"},model:{value:(_vm.form.log),callback:function ($$v) {_vm.$set(_vm.form, "log", $$v)},expression:"form.log"}})],1)],1),_c('div',{staticClass:"demo-drawer__footer",staticStyle:{"text-align":"right","margin-top":"50px"}},[_c('el-button',{on:{"click":_vm.closeDialog}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){return _vm.submit()}}},[_vm._v("确 定")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }